@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 3s linear infinite;
}

.h-inner {
  height: calc(var(--innerh) - 80px);
}

.max-h-inner {
  max-height: calc(var(--innerh) - 80px);
}

.h-video-on-video-uploader {
  height: calc(var(--innerh) - 540px);
}

.max-h-video-on-video-uploader {
  max-height: calc(var(--innerh) - 540px);
}

@keyframes audio-play {
  from {
    width: 0px;
  }

  to {
    width: 100%;
  }
}

.group:hover .group-hover\:visible {
  visibility: visible;
}